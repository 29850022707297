<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.help.heading') }}
    </h3>
    <t-grid>
      <t-grid-row>
        <t-grid-cell :size="6">
          <t-card>
            <h2>{{ $t('pages.help.getting_started.heading') }}</h2>
            <t-grid>
              <t-grid-row>
                <t-grid-cell :size="8">
                  <p>
                    {{ $t('pages.help.getting_started.lead') }}
                  </p>
                </t-grid-cell>
                <t-grid-cell :size="4" style="display: flex; justify-content: flex-end">
                  <t-icon :icon="['far', 'question-circle']" boxed />
                </t-grid-cell>
              </t-grid-row>
            </t-grid>
            <a href="https://praktikumswoche.de/hilfe?group=unternehmen" target="_blank">
              {{ $t('pages.help.getting_started.actions.discover_help_area') }}
            </a>
          </t-card>
        </t-grid-cell>
        <t-grid-cell :size="6">
          <t-card>
            <h2>{{ $t('pages.help.support.heading') }}</h2>
            <t-grid>
              <t-grid-row>
                <t-grid-cell :size="8">
                  <p>
                    {{ $t('pages.help.support.lead') }}
                  </p>
                </t-grid-cell>
                <t-grid-cell :size="4" style="display: flex; justify-content: flex-end">
                  <t-icon icon="phone" boxed />
                </t-grid-cell>
              </t-grid-row>
            </t-grid>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <strong>{{ $t('fields.email') }}:</strong>
            <a :href="`mailto:${meta.support_email}`" target="_blank">{{ meta.support_email }}</a><br>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <strong>{{ $t('fields.phonenumber') }}:</strong>
            <a :href="`tel:${meta.support_phonenumber}`" target="_blank">{{
              meta.support_phonenumber
            }}</a>
          </t-card>
        </t-grid-cell>
      </t-grid-row>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>
